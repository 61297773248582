import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { useWindowScroll } from 'react-use';
import styled from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'

const Header = (props) => {
	const {x, y} = useWindowScroll();

	return (
		<Container>
			Header
		</Container>	
	)
}


const Container = styled.div`
	${container}
	${padding}
`

export default Header
